"use strict";
window.esign = window.esign || {};
var navOpen = false;

esign.cacheSelectors = function () {
  esign.cache = {
    // general
    $body: $("body"),

    // navigation
    $nav: $(".main-nav__wrap"),

    // overview
    $propertiesOverview: $("#properties-overview"),
    $regionContent: $("#region-content"),
    propertiesOverviewIsLoading: 0,
    $stayInformedTitle: $("#js-stay-informed-title"),
    stayInformedTitleText: "",
  };
};

esign.init = function () {
  // esign.gaDevelopment();
  esign.cacheSelectors();
  esign.animateOnScroll();
  // object fit polyfill
  //objectFitImages('.cta__image img, .landscape-slideshow__img img, .thumbs-list__visual img, .overview-results__static-map-img img');
  //objectFitImages();
  esign.responsiveVideos();
  esign.formAjax();
  esign.modaal();
  esign.modaalOverlay();
  esign.appointmentPopup();
  // esign.sortResultDropdown();
  esign.scrollTo();
  esign.autocomplete();
  esign.accordion();
  esign.slideshow();
  esign.lightGallery();
  esign.cookieNotification();

  esign.inputs();
  esign.select2();
  esign.expandable();
  esign.counter();

  // ==> in esign.js <==
  objectFitImages();

  if (esign.cache.$propertiesOverview.length > 0) {
    esign.propertiesOverview();
  }

  esign.howWeWork();
  esign.jobDetail();
  esign.quoteSlider();
  esign.tableRowBlockLink();
  esign.collapseReadMore();
  esign.projectUnits();
};

esign.responsiveVideos = function () {
  $(
    'iframe[src*="youtube.com/embed"], iframe[src*="youtube-nocookie.com/embed"], iframe[src*="player.vimeo"]'
  ).wrap('<div class="video-container"></div>');
};

esign.animateOnScroll = function () {
  AOS.init({
    once: true,
    duration: 800,
  });

  document.addEventListener("aos:in:super-duper", ({ detail }) => {
    detail.classList.add("animated");
  });

  document.addEventListener("aos:out", ({ detail }) => {
    detail.classList.remove("animated");
  });

  document.addEventListener("aos:in:jumbotron", ({ detail }) => {
    console.log(detail);
    detail.classList.add("animated");
  });
};

// Ajax newsletter subscribe
esign.formAjax = function () {
  $(".form-ajax").submit(function (e) {
    var $form = $(this);
    $form.find('button,input[type="submit"]').attr("disabled", "disabled");

    $.post($form.attr("action"), $form.serializeArray(), function (data) {
      if (data.errors === false) {
        $form.html(data.result);
      } else {
        $form.find(".result").html(data.result);
        $form.find('button, input[type="submit"]').removeAttr("disabled");
      }
    });

    e.preventDefault();
    return false;
  });
};

// Log ga calls in development
esign.gaDevelopment = function () {
  if (typeof ga === typeof undefined) {
    window.ga = function () {
      var argumentsArray = [];
      for (var key in arguments) {
        if (arguments.hasOwnProperty(key)) {
          argumentsArray.push(arguments[key]);
        }
      }

      var msg =
        "[GA DEV] Call with arguments [" + argumentsArray.join(",") + "]";
      console.log("%c" + msg, "background: #ff9800; color: #fff;");
    };
  }
};

esign.cookieNotification = function () {
  var cookieNotification = document.querySelector(".js-cookie-notification");
  var cookieNotificationTriggers = document.querySelectorAll(
    ".js-cookie-notification__trigger"
  );
  var buttonCookieAll = document.querySelector(".js-cookie-all");
  var buttonCookieCustom = document.querySelector(".js-cookie-custom");
  var checkboxAnalytics = document.querySelector(".js-cookie__analytics");
  var checkboxMarketing = document.querySelector(".js-cookie__marketing");

  window.cookieNotification = {};
  window.cookieNotification.cookieSettings = {
    analytics: false,
    marketing: false,
  };

  // Set different cookies
  cookieVariables = ["analytics", "marketing"];
  var updatePreferences = function () {
    for (var i = 0; i < cookieVariables.length; i++) {
      var cookieSetting = cookieVariables[i];
      var cookieName = "cookie_settings__" + cookieSetting;
      window.cookieNotification.cookieSettings[cookieSetting] =
        esign.readCookie(cookieName);
    }
  };

  var triggerGTMEvent = function () {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "cookieNotification",
    });
  };

  var createCookies = function (cookies) {
    var objectKeys = Object.keys(cookies);
    for (var index = 0; index < objectKeys.length; index++) {
      var key = objectKeys[index];
      var cookieName = "cookie_settings__" + key;
      var value = cookies[key];

      esign.createCookie(cookieName, value, 365);
    }

    cookieNotification.classList.add("hide");
    updatePreferences();
    triggerGTMEvent();
  };

  var showCookieNotification = function () {
    checkboxAnalytics.checked =
      window.cookieNotification.cookieSettings.analytics === "true";
    checkboxMarketing.checked =
      window.cookieNotification.cookieSettings.marketing === "true";

    cookieNotification.classList.remove("hide");

    buttonCookieAll.addEventListener("click", function () {
      createCookies({ analytics: true, marketing: true });
    });

    buttonCookieCustom.addEventListener("click", function () {
      createCookies({
        analytics: checkboxAnalytics.checked,
        marketing: checkboxMarketing.checked,
      });
    });
  };

  updatePreferences();

  // check cookies
  for (var j = 0; j < cookieVariables.length; j++) {
    var variable = cookieVariables[j];
    var cookieName = "cookie_settings__" + variable;
    if (!esign.readCookie(cookieName)) {
      showCookieNotification();
    }
  }

  for (var k = 0; k < cookieNotificationTriggers.length; k++) {
    var trigger = cookieNotificationTriggers[k];
    trigger.addEventListener("click", function () {
      showCookieNotification();
    });
  }

  triggerGTMEvent();

  // check form
  var form = document.querySelector(".cookie-notication__form");
  var toggleForm = document.querySelectorAll(".js-toggle-cookie-form");
  for (var l = 0; l < toggleForm.length; l++) {
    var trigger = toggleForm[l];
    trigger.addEventListener("click", function (e) {
      e.preventDefault();
      form.classList.toggle("show");
    });
  }
};

esign.appointmentPopup = function () {
  var trigger = $(".js-modal-popup-trigger");
  var scrollpos = 0;
  var delay = 6000;

  // Cookie set means we don't show the modal again
  if (!trigger.length || esign.readCookie("modaal-appointment-form")) {
    return null;
  }

  trigger.modaal({
    content_source: "#modal-popup",
    start_open: false,
    overlay_opacity: 0.5,
    width: 700,
    custom_class: "modaal--appointment modaal--form",

    // Maintain scroll position after opening modal
    before_open: function () {
      var doc = document.documentElement;
      var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
      scrollpos = top;
      var marginTop = "-" + scrollpos + "px";
      $(".page-wrap").css("margin-top", marginTop);
    },

    after_open: function () {
      esign.createCookie("modaal-appointment-form", 1, 14);
    },

    after_close: function () {
      $(".page-wrap").css("margin-top", "0px");
      window.scrollTo(0, scrollpos);
    },
  });

  $(".js-modal-popup-close").on("click", function () {
    $(this).css("visibility", "hidden");
    trigger.modaal("close");
  });

  // Trigger modal after x amount of seconds
  setTimeout(function () {
    trigger.modaal("open");
  }, delay);
};

esign.modaal = function () {
  var scrollpos = 0;
  var smallHeader = false;

  var modaal = $(".modal-nav-trigger").modaal({
    background_scroll: false,
    overlay_opacity: 0.5,
    background: "rgb(255, 255, 255)",
    fullscreen: true,
    hide_close: true,
    overlay_close: true,
    custom_class: "modaal--drawer",

    before_open: function () {
      var doc = document.documentElement;
      var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
      scrollpos = top;

      var marginTop = "-" + scrollpos + "px";
      $(".page-wrap").css("margin-top", marginTop);
      smallHeader = $(".page-header").hasClass("page-header__small");
    },

    after_open: function () {
      if (smallHeader === true) {
        $(".page-header").addClass("page-header__small");
        $(".page-header").removeClass("page-header__big");
      }
    },

    after_close: function () {
      navOpen = false;
      $(".page-wrap").css("margin-top", "0px");
      window.scrollTo(0, scrollpos);
    },
  });

  $(".modaal-nav-close").on("click", function () {
    $(".modaal-container").addClass("slideOut");
    $(this).css("visibility", "hidden");
    setTimeout(function () {
      $(modaal).modaal("close");
      $(".modaal-nav-close").css("visibility", "visible");
    }, 600);
  });
};

esign.modaalOverlay = function () {
  $(".js-modaal-overlay").modaal({
    type: "ajax",
    overlay_opacity: 0.5,
    background: "rgb(255, 255, 255)",
  });

  // cta modaal
  var $ctaModaal = $(".js-cta-modaal");
  if ($ctaModaal.length && !esign.readCookie("modaal-cta")) {
    $ctaModaal.modaal({
      after_open: function () {
        esign.createCookie("modaal-cta", "popup", 300);
      },
    });

    setTimeout(function () {
      $ctaModaal.modaal("open");
    }, 50000);
  }

  // documents form modal
  if (!esign.readCookie("infini_doc")) {
    $(".js-download-form .js-download-form__link").each(function (i, el) {
      var $el = $(el);
      $el
        .data("href", $el.attr("href"))
        .attr("href", "#document-form-modal")
        .modaal({
          content_source: "#document-form-modal",
          type: "inline",
          overlay_opacity: 0.8,
          background: "rgb(255, 255, 255)",
          background_scroll: true,
          custom_class: "modaal--form",
        });
    });
  }
};

esign.createCookie = function (name, value, days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = "; expires=" + date.toGMTString();
  } else var expires = "";

  document.cookie = name + "=" + value + expires + "; path=/";
};

esign.readCookie = function (name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
};

esign.sortResultDropdown = function () {
  var sortDropdownActive = false;

  $(window).click(function () {
    sortDropdownActive = false;
    if (!sortDropdownActive) checkSort();
  });

  $("#sortResults").on("click", function (e) {
    e.stopPropagation();
    sortDropdownActive = !sortDropdownActive;
    checkSort();
  });

  function checkSort() {
    if (sortDropdownActive) {
      $(".offer-results__dropdown-content").css("display", "block");
    } else {
      $(".offer-results__dropdown-content").css("display", "none");
    }
  }
};

esign.scrollTo = function () {
  $(".js-scroll-to").click(function (e) {
    e.preventDefault();

    var $this = $(this),
      offset = $this.data("offset");

    if (offset == undefined) {
      offset = -120;
    }

    $(window).scrollTo($($this.attr("href")), 1000, {
      axis: "y",
      offset: offset,
    });
  });
};

esign.accordion = function () {
  $(".accordion__title.active").next(".accordion__content").slideToggle("0");

  $(".accordion__title").click(function (e) {
    e.preventDefault();

    if ($(this).hasClass("active")) {
      $(this).removeClass("active").next(".accordion__content").slideToggle("fast");
    } else {
      // close all other accordions first if on project page
      if ($(this).hasClass("accordion__title--project-units")) {
        $(".accordion__title--project-units.active").removeClass("active").next(".accordion__content").slideToggle("fast");
      }

      $(this).addClass("active").next(".accordion__content").slideToggle("fast");
    }
  });

  $(".js-accordion-trigger").click(function (e) {
    var $target = $($(this).attr("href"));
    $target.toggle("slow");
  });
};

esign.projectUnits = function () {
  var $projectUnitsSlider = $(".project-units__slider");

  $(".project-units .accordion__title").click(function (e) {
    var $this = $(this);
    var index = e.currentTarget.dataset.unitIndex;

    if (index && $this.hasClass('active')) {
      $projectUnitsSlider.slick('slickGoTo', index);
    }
  });
}

esign.slideshow = function () {
  $(".landscape-slideshow-detail").slick({
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    prevArrow:
      '<button type="button" class="slick-prev"><svg class="icon icon-arrow-long-left"><use xlink:href="#icon-arrow-long-left" /></svg></button>',
    nextArrow:
      '<button type="button" class="slick-next"><svg class="icon icon-arrow-long-right"><use xlink:href="#icon-arrow-long-right" /></svg></button>',
  });

  setTimeout(function() {
    // $(".landscape-slideshow__preloader").hide();
    $(".landscape-slideshow-hero").show();
  }, 3500);

  $(".landscape-slideshow-hero").slick({
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3500,
  });

  $(".project__slideshow").slick({
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    dots: true,
    arrows: true,
    prevArrow:
      '<button type="button" class="slick-prev"><svg class="icon icon-arrow-long-left"><use xlink:href="#icon-arrow-long-left" /></svg></button>',
    nextArrow:
      '<button type="button" class="slick-next"><svg class="icon icon-arrow-long-right"><use xlink:href="#icon-arrow-long-right" /></svg></button>',
  });

  $(".project-units__slider").slick({
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true,
    arrows: true,
    dots: true,
    prevArrow:
      '<button type="button" class="slick-prev"><svg class="icon icon-arrow-long-left"><use xlink:href="#icon-arrow-long-left" /></svg></button>',
    nextArrow:
      '<button type="button" class="slick-next"><svg class="icon icon-arrow-long-right"><use xlink:href="#icon-arrow-long-right" /></svg></button>',
  });
};

esign.lightGallery = function () {
  $(".lightgallery").each(function () {
    var $this = $(this),
      galleryId = 1;

    if ($this.data("lg-id")) {
      galleryId = $this.data("lg-id");
    }

    $this.lightGallery({
      selector: "a",
      galleryId: galleryId,
      download: false,
      thumbWidth: 90,
      thumbHeight: 90,
      // counter: false,
      thumbMargin: 10,
      thumbContHeight: 110,
      toogleThumb: false,
      youtubePlayerParams: {
        modestbranding: 1,
        showinfo: 0,
        rel: 0,
        controls: 1,
        loop: 1,
      },
    });
  });

  // trigger lightgallery by data-gallery and data-index (e.g. project image slider and project units slider)
  var isDraggingGallery = false;
  $(".trigger-lightgallery[data-gallery]")
  .click(function(e) {
    e.preventDefault();
  })
  .mousedown(function() {
    isDraggingGallery = false;
  })
  .mousemove(function() {
    isDraggingGallery = true;
  })
  .mouseup(function(e) {
    var wasDragging = isDraggingGallery;
    isDraggingGallery = false;
    if (!wasDragging) {
      // only open lightgallery if we're opening the active slide
      var $target = $(e.currentTarget);
      var $activeSlide = $target.closest('.slick-slide.slick-active');
      if (!$activeSlide.length) {
        return;
      }
  
      var galleryId = e.currentTarget.dataset.gallery;
      var galleryIndex = e.currentTarget.dataset.index;
  
      var $targetGallery = $(".lightgallery[data-lg-id='" + galleryId + "']");
  
      if ($targetGallery.length) {
        var $targetGalleryLinks = $targetGallery.find('a');
        $($targetGalleryLinks.get(galleryIndex)).trigger(
          "click"
        );
      }
    }
  });

  var $thumbs = $(".thumbs-list a"),
    $heroSlideshow = $(".property__hero .landscape-slideshow");

  if ($heroSlideshow.length) {
    $(document.documentElement).keyup(function (e) {
      // slideshow keyboard handler
      if (
        e.target.nodeName === "BODY" &&
        $(window).scrollTop() < $(window).height()
      ) {
        if (e.keyCode == 39) {
          $heroSlideshow.slick("slickNext");
        }

        if (e.keyCode == 37) {
          $heroSlideshow.slick("slickPrev");
        }
      }
    });

    $($heroSlideshow.find(".trigger-lightgallery")).click(function (e) {
      e.preventDefault();

      if (e.currentTarget.classList.contains('button--video-trigger')) {
        $($thumbs[$thumbs.length - 1]).trigger('click');

        // Select the iframe and its parent elements
        const iframe = document.querySelector(".lg-current iframe");
        const iframeParent = iframe.parentElement;
        const iframeGrandparent = iframeParent.parentElement;

        // Remove classes
        iframe.removeAttribute("class");
        iframeParent.removeAttribute("class");

        // Set styles
        iframe.style.height = "100%";
        iframe.style.width = "100%";
        iframeParent.style.height = "100%";
        iframeGrandparent.style.height = "100%";


        setTimeout(() => {
          document.querySelector("iframe").click();
        }, 2000);
      }

      $($thumbs.get($heroSlideshow.slick("slickCurrentSlide"))).trigger(
        "click"
      );
    });

    $heroSlideshow.find(".cycle-prev, .cycle-next").click(function (e) {
      var opts = $heroSlideshow.data("cycle.opts");
      var galleryIndex = opts.nextSlide;

      if ($(this).hasClass("cycle-prev")) {
        galleryIndex = opts.currSlide - 1;
      }

      if (galleryIndex < 0) {
        // take last item
        $($thumbs.get($thumbs.length - 1)).trigger("click");
      } else {
        $($thumbs.get(galleryIndex)).trigger("click");
      }
    });
  }

  // iframe & video
  $(".trigger-lightgallery-self").each(function () {
    $(this).lightGallery({
      selector: "this",
      download: false,
      counter: false,
      zoom: false,
      iframeMaxWidth: "100%",
      videoMaxWidth: "1600px",
      youtubePlayerParams: {
        modestbranding: 1,
        showinfo: 0,
        rel: 0,
        controls: 1,
      },
    });
  });
};

esign.inputs = function () {
  var hasValueClass = "input-group--has-value";

  $(".input-group__input").on("input", function () {
    var $inputGroup = $(this).closest(".input-group");
    if (this.value) {
      $inputGroup.addClass(hasValueClass);
    } else {
      $inputGroup.removeClass(hasValueClass);
    }
  });
};

esign.select2 = function () {
  $(".js-select2").each(function () {
    var $this = $(this);
    var placeholder = $this.data("placeholder");

    $this.select2({
      placeholder: placeholder,
      allowClear: false,
    });
  });
};

esign.expandable = function () {
  $(".expandable__title").click(function (e) {
    $(this).toggleClass("expandable__title--open");
  });
};

esign.counter = function () {
  var counterDays = document.getElementById("counter-days");
  var today = new Date();

  // Calculate the number of days until the next Friday
  var daysUntilFriday = 5 - today.getDay();

  // If today is Friday or later, add a week
  if (daysUntilFriday <= 0) {
    daysUntilFriday += 7;
  }

  // Calculate the date of the upcoming Friday
  var upcomingFriday = new Date();
  upcomingFriday.setDate(today.getDate() + daysUntilFriday);
  upcomingFriday.setHours(0, 0, 0, 0);

  if (counterDays && upcomingFriday) {
    var counterHours = document.getElementById("counter-hours");
    var counterMin = document.getElementById("counter-min");
    var counterSec = document.getElementById("counter-sec");

    var intervalCounter = function () {
      var dateNow = new Date();
      var seconds = Math.floor((upcomingFriday - dateNow) / 1000);

      if (seconds < 0) {
        seconds = 0;
      }

      var minutes = Math.floor(seconds / 60);
      var hours = Math.floor(minutes / 60);
      var days = Math.floor(hours / 24);

      hours = hours - days * 24;
      minutes = minutes - days * 24 * 60 - hours * 60;
      seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

      counterDays.innerText = esign.getNumberWithZero(days);
      counterHours.innerText = esign.getNumberWithZero(hours);
      counterMin.innerText = esign.getNumberWithZero(minutes);
      counterSec.innerText = esign.getNumberWithZero(seconds);
    };

    intervalCounter();
    setInterval(intervalCounter, 1000);

    $(".properties-countdown__counter").addClass("active");
  }
};

esign.getNumberWithZero = function (number) {
  return ("0" + number).slice(-2);
};

/* overview filters */
esign.propertiesOverview = function () {
  esign.initOverview();

  window.addEventListener("popstate", function (e) {
    esign.requestOverview();
  });
};

esign.initOverview = function () {
  var $filtersForm = $(".filters-form");
  var $locationsForm = $(".locations-form");
  var filterTimeout;
  var triggerSave = false;

  $(".js-show-filters").click(function (e) {
    e.preventDefault();
    $(".filters-form").toggle("fast");
    $(this).toggleClass("active");
  });

  // sort properties
  esign.cache.$propertiesOverview.on("change", "#filter-sort", function () {
    // edit hidden input
    var sortValue = $(this).val();
    $filtersForm.find('input[name="sort"]').val(sortValue);
    $filtersForm.submit();
  });

  // on filter change
  esign.cache.$propertiesOverview.on(
    "change",
    '.filters-form input[type="checkbox"]',
    function () {
      clearTimeout(filterTimeout);
      filterTimeout = setTimeout(function () {
        $filtersForm.submit();
      }, 200);
    }
  );

  esign.cache.$propertiesOverview.on(
    "focus",
    ".filters-form input",
    function () {
      clearTimeout(filterTimeout);
    }
  );

  esign.cache.$propertiesOverview.on(
    "blur",
    ".filters-form input",
    function () {
      clearTimeout(filterTimeout);
      filterTimeout = setTimeout(function () {
        $filtersForm.submit();
      }, 200);
    }
  );

  // remove filter
  esign.cache.$propertiesOverview.on(
    "click",
    ".selected-filters__item",
    function () {
      var $this = $(this);
      var name = $(this).data("name");

      if (name === "zip") {
        $locationsForm
          .find(".js-select2")
          .val([])
          .select2({
            placeholder: $locationsForm.find(".js-select2").data("placeholder"),
            allowClear: true,
            dropdownAutoWidth: true,
          });
      } else {
        var $inputs = esign.cache.$propertiesOverview.find(
          'input[name="' + name + '[]"]'
        );
        $inputs.filter('[type="checkbox"]').attr("checked", false);
        $inputs.filter('[type="text"], [type="number"]').val("");
      }

      $this.remove();
      $filtersForm.submit();
    }
  );

  $filtersForm.submit(function (e) {
    if (!triggerSave) {
      e.preventDefault();

      var inputs = $filtersForm.serializeArray();
      var url = window.baseOverviewUrl;

      // check if projects
      if (esign.cache.$body.hasClass("projects-index")) {
        url = esign.buildProjectsOverviewUrl(inputs);
      } else {
        // properties
        var locations = $locationsForm.serializeArray();
        url = esign.buildOverviewUrl(locations, inputs);
      }

      history.pushState(null, null, url);
      esign.requestOverview();
    }
  });

  $locationsForm.submit(function (e) {
    e.preventDefault();
    $filtersForm.submit();
  });

  $locationsForm.find("select").change(function (e) {
    e.preventDefault();
    $filtersForm.submit();
  });

  $(".js-trigger-save-search").click(function (e) {
    triggerSave = true;
    $filtersForm.find('input[name="save"]').val("1");
    $filtersForm.submit();
  });

  // pagination requests
  esign.cache.$propertiesOverview.on("click", ".pagination a", function (e) {
    e.preventDefault();
    var url = $(this).attr("href");
    history.pushState(null, null, url);
    esign.requestOverview();
    document.documentElement.scrollTop = 0;
  });
};

esign.requestOverview = function () {
  var url = window.location.href;
  if (url.indexOf("?") > 0) {
    url += "&ajax=1";
  } else {
    url += "?ajax=1";
  }

  var $overviewResults = esign.cache.$propertiesOverview.find(
    ".overview-results, #overview-map, .projects-results"
  );

  // console.log(url);

  // set loading counter
  esign.cache.propertiesOverviewIsLoading++;
  $overviewResults.addClass("js-is-loading");

  $.getJSON(url, {}, function (data) {
    esign.cache.propertiesOverviewIsLoading--;

    // check if last json request
    if (esign.cache.propertiesOverviewIsLoading === 0) {
      $overviewResults.removeClass("js-is-loading");

      // ga is loaded
      if (typeof ga === "function") {
        // ga pageview
        var gaUrl = window.location.pathname + window.location.search;
        gaUrl = gaUrl.replace("/infinimo", "").replace("/public", "");
        ga("send", "pageview", gaUrl);
      }

      // check if new content
      if (data.content) {
        esign.cache.$propertiesOverview
          .off("change", "#filter-sort")
          .off("change", ".filters-form input")
          .off("click", ".selected-filters__item")
          .off("click", ".pagination a");

        esign.cache.$propertiesOverview.html(data.content);

        window.scrollTo(0, 0);

        if (esign.cache.stayInformedTitleText !== "") {
          // build with city name
          if (data.cityNames && data.cityNames !== "") {
            esign.cache.stayInformedTitleText +=
              ' in <strong class="text-lined">' + data.cityNames + "</strong>";
          }

          esign.cache.stayInformedTitleText += ".<br/>";

          esign.cache.$stayInformedTitle.html(
            esign.cache.stayInformedTitleText
          );
        }

        esign.initOverview();
        esign.select2();
        esign.expandable();

        if (data.title) {
          document.title = data.title;
        }

        if (data.description) {
          $("meta[name=description]").attr("content", data.description);
        }

        if (data.regionContent) {
          esign.cache.$regionContent.html(data.regionContent);
        } else {
          esign.cache.$regionContent.empty();
        }

        // check if markers
        if (data.markers) {
          window.markers = data.markers;
          mapview.init();
        }
      }
    }
  });
};

esign.buildOverviewUrl = function (locations, inputs) {
  var overviewUrl = window.baseOverviewUrl;

  // locations
  var citiesPath = [];
  for (var l = 0; l < locations.length; l++) {
    var location = locations[l];
    if ((location.name = "city[]")) {
      citiesPath.push(location.value);
    }
  }

  if (citiesPath.length === 0) {
    overviewUrl += "alle";
  } else {
    overviewUrl += citiesPath.join("+");
  }

  // filter inputs
  var typesPath = [];
  var filterParams = [];

  // loop form inputs
  for (var i = 0; i < inputs.length; i++) {
    var input = inputs[i];
    var inputName = input.name;
    var inputValue = input.value;

    if (inputName === "type[]") {
      // push value of type
      typesPath.push(inputValue);
    } else {
      // its a parameter
      // input is not an array
      if (inputName.indexOf("[]") === -1) {
        // exclude items which are empty
        if (inputValue === "") {
          continue;
        }
      } else {
        // lookup other values from array
        var result = $.grep(inputs, function (item) {
          return item.name === inputName && item.value !== "";
        });

        // exclude arrays which are empty
        if (result.length === 0) {
          continue;
        }
      }

      var param = inputName + "=" + inputValue;
      filterParams.push(param);
    }
  }

  // check types
  if (typesPath.length > 0) {
    overviewUrl += "/" + typesPath.join("+");
  } else {
    overviewUrl += "/alle";
  }

  // check filter params
  if (filterParams.length > 0) {
    overviewUrl += "?" + filterParams.join("&");
  }

  // set stay informed text
  esign.cache.stayInformedTitleText = "U zocht op ";

  if (typesPath.length > 0) {
    var stayInformedTypes = typesPath.join(
      '</strong>, <strong class="text-lined">'
    );

    var lastIndex = stayInformedTypes.lastIndexOf(", ");
    if (lastIndex > 0) {
      stayInformedTypes =
        stayInformedTypes.substring(0, lastIndex) +
        " &" +
        stayInformedTypes.substring(lastIndex + 1);
    }

    esign.cache.stayInformedTitleText +=
      '<strong class="text-lined">' + stayInformedTypes + "</strong>";
  }

  if ($("#transaction-switch .switch__btn--active").data("name") == "rent") {
    esign.cache.stayInformedTitleText += " te huur";
  } else {
    esign.cache.stayInformedTitleText += " te koop";
  }

  return overviewUrl;
};

esign.buildProjectsOverviewUrl = function (inputs) {
  var overviewUrl = window.baseOverviewUrl;
  var filterParams = [];

  // loop form inputs
  for (var i = 0; i < inputs.length; i++) {
    var input = inputs[i];
    var inputName = input.name;
    var inputValue = input.value;

    // its a parameter
    // input is not an array
    if (inputName.indexOf("[]") === -1) {
      // exclude items which are empty
      if (inputValue === "") {
        continue;
      }
    } else {
      // lookup other values from array
      var result = $.grep(inputs, function (item) {
        return item.name === inputName && item.value !== "";
      });

      // exclude arrays which are empty
      if (result.length === 0) {
        continue;
      }
    }

    var param = inputName + "=" + inputValue;
    filterParams.push(param);
  }

  // check filter params
  if (filterParams.length > 0) {
    overviewUrl += "?" + filterParams.join("&");
  }

  return overviewUrl;
};

/* search autocomplete */
esign.autocomplete = function () {
  var $searchBarWrapper = $(".search-bar"),
    $searchBar = $("#search-all-bar"),
    $searchBarAutocomplete = $("#search-all-bar-autocomplete"),
    $searchBarAutocompleteDefault = $("#search-all-bar-autocomplete-default"),
    $searchBarAutocompleteResults = $("#search-all-bar-autocomplete-results"),
    $removeLabelBtn = $(".search-bar__remove-label-btn"),
    $autocompleteFocusEl = $searchBarAutocompleteDefault.find("a"),
    $autocompleteFocusElActive = undefined,
    autocompleteInfo = $(
      '<p class="info">Gelieve 2 of meer karakters in te voeren...</p>'
    ),
    autocompleteNoResults = $(
      '<p class="info">Er zijn geen resultaten gevonden.</p>'
    );

  var showAutocomplete = function () {
    $searchBarAutocomplete.addClass("show");
  };

  var hideAutocomplete = function () {
    $searchBarAutocomplete.removeClass("show");
  };

  var updateAutocomplete = function () {
    var value = $searchBar.val();

    if (value !== "") {
      // search results
      $searchBarAutocompleteDefault.hide();
      $searchBarAutocompleteResults.show();

      var data = {
        q: value,
        type: $("#property-type").val(),
        filters: $(".filter-field.persistant")
          .filter(function () {
            return this.value !== "";
          })
          .serialize(),
      };

      $.getJSON(window.searchApi, data, function (data) {
        var template = "";

        if (data.length === 0) {
          template = autocompleteNoResults;
        }

        if (data.agents && data.agents.length > 0) {
          template += esign.autocompleteThumbsTemplate(
            "Makelaars",
            data.agents,
            "grid profiles"
          );
        }

        if (data.cities && data.cities.length > 0) {
          template += esign.autocompleteListingTemplate(
            "Locaties",
            data.cities
          );
        }

        if (data.types && data.types.length > 0) {
          template += esign.autocompleteListingTemplate(
            "Type aanbod",
            data.types
          );
        }

        if (data.properties && data.properties.length > 0) {
          template += esign.autocompleteThumbsTemplate(
            "Panden",
            data.properties,
            "list"
          );
        }

        $searchBarAutocompleteResults.html(template);
        $autocompleteFocusEl = $searchBarAutocompleteResults.find("a");
        $autocompleteFocusElActive = undefined;
      });
    } else {
      // show default results
      $searchBarAutocompleteDefault.show();
      $searchBarAutocompleteResults.hide();

      $autocompleteFocusEl = $searchBarAutocompleteDefault.find("a");
      $autocompleteFocusElActive = undefined;
    }
  };

  var navigateAutocomplete = function (charCode) {
    switch (charCode) {
      case 40:
      case 39:
        if ($autocompleteFocusElActive == undefined) {
          // first element
          $autocompleteFocusElActive = $autocompleteFocusEl.first();
        } else {
          $autocompleteFocusElActive.removeClass("focus-item");

          // get next element
          var index =
            $autocompleteFocusEl.index($autocompleteFocusElActive) + 1;

          if (index > $autocompleteFocusEl.length) {
            index = $autocompleteFocusEl.length;
          }

          $autocompleteFocusElActive = $($autocompleteFocusEl[index]);
        }

        break;

      case 38:
      case 37:
        if ($autocompleteFocusElActive) {
          $autocompleteFocusElActive.removeClass("focus-item");

          // get prev element
          var index =
            $autocompleteFocusEl.index($autocompleteFocusElActive) - 1;

          if (index < 0) {
            index = 0;
          }

          $autocompleteFocusElActive = $($autocompleteFocusEl[index]);
        }

        break;

      case 13:
        // go to link on enter
        var link = $autocompleteFocusEl.first().attr("href");
        if (
          $autocompleteFocusElActive &&
          $autocompleteFocusElActive.attr("href")
        ) {
          link = $autocompleteFocusElActive.attr("href");
        }

        if (link) {
          window.location = link;
        }

        break;

      case 27:
        // hide on escape
        hideAutocomplete();
        return;

        break;
    }

    // set current focus el
    if ($autocompleteFocusElActive) {
      $autocompleteFocusElActive.addClass("focus-item");

      var itemTitle = $autocompleteFocusElActive.attr("title");

      if (itemTitle) {
        $searchBar.val(itemTitle);
      } else {
        $searchBar.val($autocompleteFocusElActive.text());
      }
    }
  };

  $searchBar
    .on("focus", function (e) {
      showAutocomplete();
    })
    .on("keyup", function (e) {
      var navigationKeys = [13, 38, 40, 27];
      if (navigationKeys.indexOf(e.which) > -1) {
        navigateAutocomplete(e.which);
      } else {
        showAutocomplete();
        updateAutocomplete();
      }
    });

  $autocompleteFocusEl.on("focusin", function (e) {
    var itemTitle = $(this).attr("title");

    if (itemTitle) {
      $searchBar.val(itemTitle);
    }
  });

  $(document).on("mouseup", function (e) {
    if (
      !$searchBar.is(e.target) &&
      $searchBar.has(e.target).length === 0 &&
      !$searchBarAutocomplete.is(e.target) &&
      $searchBarAutocomplete.has(e.target).length === 0 &&
      $searchBarAutocomplete.hasClass("show")
    ) {
      hideAutocomplete();
    }
  });

  if ($searchBarWrapper.hasClass("search-label-set")) {
    // make item for text length
    var $searchBarLengthItem = $(
      '<div class="search-bar-length-item"></div>'
    ).text($searchBar.val());

    $searchBarWrapper.append($searchBarLengthItem);

    // get offset
    var offsetX = $searchBarLengthItem.width() + 20;
    // remove item
    $searchBarLengthItem.remove();
    // add offset
    $removeLabelBtn.addClass("show").css("margin-left", offsetX + "px");

    $removeLabelBtn.click(function (e) {
      e.preventDefault();
      $searchBarWrapper.removeClass("search-label-set");
      $removeLabelBtn.removeClass("show");
      $('input[name="search"]').val("");
      $searchBar.val("").focus();
    });
  }

  // Focus search bar when it is on the homepage and the scroll position is at the hero
  // if (
  //   $(document.body).hasClass('home') &&
  //   $(document).scrollTop() < $(window).height()
  // ) {
  //   setTimeout(function() {
  //     $searchBar.focus();
  //   }, 1000);
  // }

  // mobile placeholder
  if ($(window).width() < 767) {
    $searchBar.attr("placeholder", "Zoeken");
  }
};

/* template for listings */
esign.autocompleteListingTemplate = function (label, items) {
  var template =
    '<h6 class="search-bar-results__title">' +
    label +
    '</h6><ul class="search-results-listing">';

  var titleSaleUrl = true;
  if (
    $("body").hasClass("properties-index") &&
    $("#transaction-switch .switch__btn--active").data("name") == "rent"
  ) {
    titleSaleUrl = false;
  }

  var templateArray = [];
  for (var i = 0, j = items.length; i < j; i++) {
    var item = items[i];

    var getButton = function (label, data, parentLabel) {
      var template = "",
        buttonLabel = label + " (" + data.count + ")";

      if (data.count == 0) {
        template = '<span class="search-results-listing-item__button">';
      } else {
        template =
          '<a href="' +
          data.url +
          '" title="' +
          parentLabel +
          " - " +
          buttonLabel +
          '" class="search-results-listing-item__button">';
      }

      template += buttonLabel;

      if (data.count == 0) {
        template += "</span>";
      } else {
        template += "</a>";
      }

      return template;
    };

    var titleUrl = item.sale.url;
    var activeItem = true;

    if (item.sale.count == "0") {
      activeItem = false;
    }

    if (!titleSaleUrl) {
      titleUrl = item.rent.url;

      if (item.rent.count == "0") {
        activeItem = false;
      }
    }

    var itemTemplate = "<li>";

    if (activeItem) {
      itemTemplate +=
        '<p class="search-results-listing-item__title">' +
        '<a href="' +
        titleUrl +
        '">' +
        item.label +
        "</a>" +
        "</p>";
    } else {
      itemTemplate +=
        '<p class="search-results-listing-item__title search-results-listing-item__title--inactive">' +
        item.label +
        "</p>";
    }

    if (!$("body").hasClass("properties-index")) {
      itemTemplate += '<p class="search-results-listing-item__buttons">';

      itemTemplate += getButton("Te koop", item.sale, item.label);

      itemTemplate += getButton("Te huur", item.rent, item.label);

      itemTemplate += "</p>";
    }

    itemTemplate += "</li>";

    templateArray.push(itemTemplate);
  }

  template += templateArray.join("");

  template += "</ul>";

  return template;
};

/* template for thumbs */
esign.autocompleteThumbsTemplate = function (label, items, modifierClass) {
  var template =
    '<h6 class="search-bar-results__title">' +
    label +
    '</h6><ul class="search-results-properties ' +
    modifierClass +
    '">';

  var templateArray = [];
  for (var i = 0, j = items.length; i < j; i++) {
    var item = items[i];

    var itemTemplate =
      '<li><a href="' +
      item.url +
      '" title="' +
      item.label +
      '">' +
      '<span class="search-results-properties__thumb" style="background-image: url(' +
      item.thumb +
      ');">' +
      "&nbsp;</span>" +
      '<span class="search-results-properties__content">' +
      '<span class="search-results-properties__label">' +
      item.label +
      " </span>";

    if (item.reference) {
      itemTemplate +=
        '<span class="search-results-properties__ref">' +
        item.reference +
        "</span>";
    }

    itemTemplate += "</span></a></li>";

    templateArray.push(itemTemplate);
  }

  template += templateArray.join("");

  template += "</ul>";

  return template;
};

esign.howWeWork = function () {
  var $howWeWorkNav = $(".how-we-work-navigation");
  var $howWeWork = $(".how-we-work");

  $howWeWorkNav.stick_in_parent({
    offset_top: 220,
  });

  $.each($howWeWorkNav.find("li"), function (index, value) {
    var $navListitem = $(value);
    var $linkElement = $navListitem.find("a");
    var link = $linkElement.attr("href");

    $linkElement.on("click", function () {
      $("html, body").animate(
        {
          scrollTop: $(link).offset().top - 100,
        },
        750
      );
    });

    var $linkedItem = $(link);

    // console.log( index, value );
    var waypoint = new Waypoint({
      element: $linkedItem,
      offset: 250,
      handler: function () {
        $howWeWorkNav.find("li").removeClass("active");
        $howWeWork.find("li").removeClass("active");
        // console.log($linkElement);

        $navListitem.addClass("active");
        $linkedItem.addClass("active");
        //console.log('Basic waypoint triggered')
      },
    });
  });
};
esign.jobDetail = function () {
  var $jobNav = $(".jobs-list-navigation");
  var $jobBody = $(".jobs-list__body");

  $jobNav.stick_in_parent({
    offset_top: 220,
  });
  /*
  $.each($jobNav.find('> li'), function( index, value ) {
    let $navListitem = $(value);
    let $linkElement = $navListitem.find('a');
    let link = $linkElement.attr('href');

    $linkElement.on('click', function () {

          $('html, body').animate({
                  scrollTop: $(link).offset().top - 100
              }, 750);

    });

    let $linkedItem = $(link);

   // console.log( index, value );
    let waypoint = new Waypoint({
      element: $linkedItem,
      offset:250,
      handler: function() {
        $jobNav.find('li').removeClass('active');
        $jobBody.find('li').removeClass('active');
       // console.log($linkElement);

        $navListitem.addClass('active');
        $linkedItem.addClass('active');
        //console.log('Basic waypoint triggered')
      }
    });

    //voor latere fase houden; moet meer logica in om mooi te laten werken; bij extra budget dus
  let $subNavItems = $navListitem.find('li');

    $.each($subNavItems, function( index, value ) {
      console.log('subnavitem');
      //console.log( index, value );
      let $subnavListitem = $(value);
      let $linkElement = $subnavListitem.find('a');
      let sublink = $linkElement.attr('href');

      let waypoint = new Waypoint({
        element: $subnavListitem,
       /!* offset: '-20%',*!/
        handler: function() {
          //$('.jobs-list__body__subsection').removeClass('active');
          $('.jobs-list-navigation__sublist li').not($subnavListitem).removeClass('active');
          $jobNav.find('li').removeClass('active');
          //console.log('subn:', $subnavListitem);
          $navListitem.addClass('active');
         //
         // $jobBody.find('li').removeClass('active');


         // console.log($linkElement);
          $navListitem.addClass('active');
          $subnavListitem.addClass('active');
          $linkedItem.addClass('active');
          //console.log('Basic waypoint triggered')
        }
      });
    });
  });

*/
};

esign.quoteSlider = function () {
  $(".client-quote-slider").slick({
    centerMode: false,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    nextArrow:
      '<button class="slick-arrow slick-arrow--right"><img width="16" src="assets/images/svg/arrow-long-right.svg" alt="Next slide"/></button>',
    prevArrow:
      '<button class="slick-arrow slick-arrow--left"><img width="16" src="assets/images/svg/arrow-long-left.svg" alt="Previous slide"/></button>',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
    ],
  });
};

esign.tableRowBlockLink = function () {
  // .table-row-link
  $(".table-row-link").click(function (e) {
    if ($(e.target).is("a,input")) {
      return;
    }

    var link = $(this).find(".table-row-link__target").attr("href");
    if (link) {
      window.location = link;
    }
  });
};

esign.collapseReadMore = function () {
  $(".property-location-body").each(function () {
    var $content = $(this);
    var $button = $content.find(".property-location-body__more");
    var minHeight = 360;
    var maxHeight = $content.outerHeight();
    var lessText = $button.data("less");
    var moreText = $button.data("more");
    var toggleClass = "property-location-body--open";
    var show = true;

    $content.removeClass(toggleClass).height(minHeight);

    $button.click(function (e) {
      if (show) {
        show = false;
        $content.addClass(toggleClass).height(maxHeight);
        $button.text(lessText);
      } else {
        show = true;
        $content.removeClass(toggleClass).height(minHeight);
        $button.text(moreText);
      }
    });
  });

  var $soldPropertiesListMore = $(".sold-properties-list__more");
  if ($soldPropertiesListMore.length > 0) {
    var $overview = $(".sold-properties-list");
    var lessText = $soldPropertiesListMore.data("less");
    var moreText = $soldPropertiesListMore.data("more");
    var toggleClass = "sold-properties-list--show-all";
    var showMore = true;

    $soldPropertiesListMore.click(function (e) {
      if (showMore) {
        showMore = false;
        $overview.addClass(toggleClass);
        $soldPropertiesListMore.text(lessText);
      } else {
        showMore = true;
        $overview.removeClass(toggleClass);
        $soldPropertiesListMore.text(moreText);
      }
    });
  }
};

// Initialize on docready
$(esign.init);
